// src/utils/localStorage.js

export const getImages = () => {
  const images = JSON.parse(localStorage.getItem('images')) || [];
  return images;
};

export const addImages = (newImageURLs) => {
  const images = getImages();
  const updatedImages = [...images, ...newImageURLs];
  localStorage.setItem('images', JSON.stringify(updatedImages));
};

export const removeImage = (index) => {
  const images = getImages();
  images.splice(index, 1);
  localStorage.setItem('images', JSON.stringify(images));
};
