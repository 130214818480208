import React, { useState, useEffect } from 'react';
import ImageUploader from './components/ImageUploader';
import ImageCarousel from './components/ImageCarousel';
import { getImages, addImages, removeImage } from './utils/localStorage';
import '@khmyznikov/pwa-install';
import logo from './logo.png'; // Importa tu logo
import './App.css';

const App = () => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        setImages(getImages());
    }, []);

    const handleUpload = (event) => {
        const files = Array.from(event.target.files);
        const readers = files.map(file => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result); // Resuelve con la URL de la imagen
                reader.onerror = error => reject(error);
                reader.readAsDataURL(file);
            });
        });

        Promise.all(readers).then(urls => {
            addImages(urls);
            setImages(getImages()); // Actualiza el estado con las nuevas imágenes
        }).catch(error => {
            console.error('Error reading files:', error);
        });
    };

    const handleRemove = () => {
        if (images.length > 0) {
            removeImage(0);
            setImages(getImages());
        }
    };

    return (
        <div className="app-container">
            <header className="header">
                <div className="logo-container">
                    <img src={logo} alt="Logo" className="logo" />
                </div>
            </header>
            <div className="carousel-container">
                <ImageCarousel images={images} />
            </div>
            <div className="bottom-bar">
                <button className="btn-materialize-green" onClick={() => document.getElementById('file-input').click()}>
                    Añadir Pase
                </button>
                {images.length > 0 && (
                    <button className="btn-materialize-red" onClick={handleRemove}>
                        Quitar Pase Actual
                    </button>
                )}
                <input id="file-input" type="file" multiple accept="image/*" onChange={handleUpload} style={{ display: 'none' }} />
            </div>
            <pwa-install
                install-description="Guarda els pases del Molí Nou a la pantalla d'inici del teu dispositiu"
            >
            </pwa-install>
        </div>
    );
};

export default App;
