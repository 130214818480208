import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './ImageCarousel.css';

const ImageCarousel = ({ images }) => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <div className="image-carousel-container">
            <Carousel activeIndex={index} onSelect={handleSelect}>
                {images.map((image, idx) => (
                    <Carousel.Item key={idx}>
                        <img
                            className="d-block w-100"
                            src={image}
                            alt={`Slide ${idx}`}
                            style={{ height: '300px', objectFit: 'contain' }}
                        />
                    </Carousel.Item>
                ))}
            </Carousel>
            <div className="thumbnail-container">
                {images.map((image, idx) => (
                    <img
                        key={idx}
                        src={image}
                        alt={`Thumbnail ${idx}`}
                        className={`thumbnail ${index === idx ? 'active' : ''}`}
                        onClick={() => setIndex(idx)}
                    />
                ))}
            </div>
        </div>
    );
};

export default ImageCarousel;
